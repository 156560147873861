@tailwind base;
@tailwind components;
@tailwind utilities;

/* baloo-2-latin-wght-normal */
@font-face {
  font-family: 'Baloo 2 Variable';
  font-style: normal;
  font-display: swap;
  font-weight: 400 800;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/baloo-2:vf@latest/latin-wght-normal.woff2) format('woff2-variations');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

body {
  margin: 0;
  font-family: 'Baloo 2 Variable', sans-serif;
  font-size: 12px;
  // background-color: '#000137';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
nav .activeLink a.active {
  border-width: 2px;
  // border-radius: 50px;
  border-start-end-radius: 50px;
  border-end-end-radius: 50px;
  border-left: 0;
  border-color: #fff;
  padding: 5;
  color: #f6f6f6;
  height: 53px;
  width: 220px;
}

nav .activeCollapseLink a.active {
  border: 2;
  border-radius: 50;
  border-color: #fff;
  padding: 5;
  color: #f6f6f6;
  height: 53px;
  width: 50px;
}